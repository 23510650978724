import React, { useContext, useState, useRef } from "react";
import "../../styles/components/dashboard/_createBanner.scss";
import AppContext from "../../context/AppContext";
import { Col, Container, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import Error from "../Error";

const initialState = {
  title: "",
  subtitle: "",
  banner: "",
};

const CreateBanner = () => {
  const [formBanner, setFormBanner] = useState(initialState);
  const [error, setError] = useState({ error: false, message: "" });
  const form = useRef(null);

  const { state, createdBanner, deleteBanner } = useContext(AppContext);
  const { banners } = state;

  const handleChange = ({ target }) => {
    setFormBanner({
      ...formBanner,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      formBanner.title.trim() === "" ||
      formBanner.subtitle.trim() === "" ||
      formBanner.banner.trim() === ""
    ) {
      setError({
        error: true,
        message: "Todos los campos son obligatorios",
      });
      return;
    }

    const formData = new FormData(form.current);
    formData.append("title", formBanner.title);
    formData.append("subtitle", formBanner.subtitle);
    formData.append("image", formBanner.banner);

    const response = await createdBanner(formData);

    if (
      response === 400 ||
      response === 401 ||
      response === 403 ||
      response === 404 ||
      response === 500
    ) {
      Swal.fire("Error!", "Hubo un error al cagar el banner", "error");
    }
    if (response === 201) {
      setFormBanner(initialState);
      Swal.fire(
        "Banner cargado exitosamente!",
        "El banner se creo exitosamente",
        "success"
      );
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, borralo!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBanner(id).then((response) => {
          if (
            response === 400 ||
            response === 401 ||
            response === 404 ||
            response === 500
          ) {
            Swal.fire("Error!", "Ha ocurrido un error.");
          }
          if (response === 204) {
            Swal.fire("Borrado!", "El banner ha sido borrado.");
          }
        });
      }
    });
  };

  return (
    <Container>
      <Row>
        {banners.map((item) => {
          return (
            <Col xs={12} key={item.id} className="Banner__col--info py-3">
              <div>
                <img src={item.image} alt={item.title} />
              </div>
              <p>
                <span>Id:</span> {item.id}
              </p>
              <p>
                <span>Título:</span> {item.title}
              </p>
              <p>
                <span>Subtitulo:</span> {item.subtitle}
              </p>
              <div>
                <button onClick={() => handleDelete(item.id)}>Borrar</button>
              </div>
            </Col>
          );
        })}
      </Row>

      <Row className="mt-3 mb-5 ">
        <Col xs={12}></Col>
        <Col xs={12} md={7} className="mb-5 Banner_container--form">
          <h2>Creación de Banner</h2>
          <form className="mb-2" ref={form}>
            <div className="form-group">
              <label htmlFor="title">Titulo</label>
              <input
                type="text"
                className="form-control"
                id="title"
                placeholder="Titulo"
                name="title"
                value={formBanner.title}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="subtitle">Subtitle</label>
              <input
                type="text"
                className="form-control"
                id="subtitle"
                placeholder="Subtitle"
                name="subtitle"
                value={formBanner.subtitle}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="banner">Banner</label>
              <input
                type="file"
                className="form-control"
                id="banner"
                placeholder="Cargar una imagen"
                name="banner"
                onChange={handleChange}
              />
            </div>

            <div>
              <button onClick={handleSubmit}>guardar</button>
            </div>
          </form>
          {error.error && <Error text={error.message} />}
        </Col>
      </Row>
    </Container>
  );
};

export default CreateBanner;
