import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext";
import "../../styles/components/dashboard/_actualizaciones.scss";
import Swal from "sweetalert2";
import { Container, Row, Col } from "react-bootstrap";

import Error from "../Error";

const initialState = {
  amount: "",
  name: "",
  description: "",
};

const Updates = () => {
  const [actualizacion, setActualizacion] = useState(initialState);
  const [error, setError] = useState({ error: false, message: "" });

  const { amount, name, description } = actualizacion;

  const navigate = useNavigate();

  const { state, createActualizacion, deleteActualizacion } = useContext(AppContext);
  const { updates } = state;

  const handleChange = (event) => {
    setActualizacion({
      ...actualizacion,
      [event.target.name]: event.target.value,
    });
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Estas seguro?",
      text: "No podrás revertit esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, borralo!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteActualizacion(id).then((response) => {
          if (response === 204) {
            Swal.fire("Borrado!", "La actualización ha sido borrada.");
          }
          if (response === 400 || response === 401 || response === 500) {
            Swal.fire("Error!", "Ha ocurrido un error.");
          }
        });
      }
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      amount.trim() === "" ||
      name.trim() === "" ||
      description.trim() === ""
    ) {
      setError({
        error: true,
        message: "Todos los campos son obligatorios",
      });
      return;
    }

    const response = await createActualizacion(actualizacion);
    if (response) {
      setActualizacion(initialState);
      setError(false);
      Swal.fire(
        "Actualización Creada!",
        "La actualización se creó correctamente",
        "success"
      );
    }
    if (response === 500) {
      Swal.fire("Error!", "Hubo un error al crear la actualización", "error");
    }
  };

  return (
    <Container className="Actualizaciones">
      <Row>
        {updates.map((item) => {
          return (
            <Col
              xs={12}
              key={item.id}
              className="d-flex flex-column flex-md-row justify-content-between Actualizaciones__col--updates"
            >
              <div>
                <p>{item.id}</p>
                <p>{item.amount}</p>
                <p>{item.name}</p>
                <p>{item.description}</p>
              </div>
              <div className="mt-2 mt-md-0">
                <button
                  onClick={() =>
                    navigate(`/administrador/editar-actualizaciones/${item.id}`)
                  }
                >
                  Actualizar
                </button>
                <button onClick={() => handleDelete(item.id)}>Borrar</button>
              </div>
            </Col>
          );
        })}
      </Row>

      <Row className="my-5">
        <Col xs={12}>
          <p>
            Nota: se recomienda no crear mas de seis actualizaciones, ya que se
            puede ver modificado el diseño de la sección.
          </p>
        </Col>
        <Col xs={12} md={6} className="Actualizaciones__col--form">
          <h2>Crear actualización</h2>
          <form className="mb-3">
            <div className="form-group mb-3">
              <label>Cantidad</label>
              <input
                className="form-control"
                type="text"
                name="amount"
                value={actualizacion.amount}
                onChange={handleChange}
                placeholder="Ingresar Cantidad"
              />
            </div>
            <div className="form-group mb-3">
              <label>Nombre</label>
              <input
                className="form-control"
                type="text"
                name="name"
                value={actualizacion.name}
                onChange={handleChange}
                placeholder="Ingresar Nombre"
              />
            </div>
            <div className="form-group mb-1">
              <label>Descripción</label>
              <input
                className="form-control"
                type="text"
                name="description"
                value={actualizacion.description}
                onChange={handleChange}
                placeholder="Ingresar Descripción"
              />
            </div>
            <div></div>
            <div className="form-group">
              <button onClick={(event) => handleSubmit(event)}>Guardar</button>
            </div>
          </form>
          {error.error && <Error text={error.message} />}
        </Col>
      </Row>
    </Container>
  );
};

export default Updates;
