import React from "react";
import { Helmet } from "react-helmet";

import WhatIsPpd from "../components/WhatIsPpd.jsx";
import PpdVenezuela from "../components/PpdVenezuela.jsx";
import Structure from "../components/Structure.jsx";
import FocalAreas from "../components/FocalAreas.jsx";
import Footer from "../components/Footer.jsx";

const AboutUs = () => {
  document.body.className = "";
  return (
    <>
      <Helmet>
        <title>PPD - Quienes Somos</title>
      </Helmet>
      <WhatIsPpd />
      <PpdVenezuela />
      <Structure />
      <FocalAreas />
      <Footer bgColor="Footer_bg-footer-green" />
    </>
  );
};

export default AboutUs;
