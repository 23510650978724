import React from "react";
import "../styles/components/_whatIsPpd.scss";

import {Container, Row, Col} from 'react-bootstrap';

import whatIsPpd from '../assets/whatIsPps/whatIsPpd.svg';
import fmam from "../assets/logos/fmam.svg";
import pnud from "../assets/logos/pnud.png";
import ppLogo from "../assets/logos/logo.svg";

const WhatIsPpd = () => {
  return (
    <Container fluid={true} className="WhatIsPpd_container">
      <Container>
        <Row>
          <Col xs={12} className="">
            <img
              src={whatIsPpd}
              className="WhatIsPpd_whatIsPpd"
              alt="Que es el PPD"
            />
          </Col>
          <Col xs={12} className="WhatIsPpd_container-paragraph">
            <p>
              {`El Programa de Pequeñas Donaciones (PPD) del Fondo para el Medio Ambiente Mundial
                (FMAM) brinda apoyo ﬁnanciero y técnico a proyectos comunitarios que contribuyen
                con la preservación y recuperación del ambiente y promueven el bienestar y los medios
                de vida sustentables de las comunidades, por medio del otorgamiento directo de donaciones 
								de hasta $50.000 dólares (en moneda local) a organizaciones comunitarias de
                base (OCB) y organizaciones no gubernamentales (ONG) que trabajan en áreas de Biodiversidad, 
								Mitigación y Adaptación al Cambio Climático y Degradación de Suelos.`}
            </p>
            <p>
              {`
                Con presencia en 125 países, el PPD incorpora en su metodología un enfoque descentralizados, 
								eﬁciente, transparente y participativo en el diseño, planiﬁcación, ejecución y
                evaluación de proyectos, con un rol activo de las comunidades en todas las etapas.`}
            </p>
            <p>
              {`El deterioro ambiental es una de las principales amenazas a nuestra existencia y la de
                las demás especies. Sin embargo, no todos se ven afectados de la misma forma.
                Aunque la destrucción de ecosistemas y de las especies que dependen de ellos, el aumento 
								de los niveles de dióxido de carbono y otros gases de efecto invernadero en la
                atmósfera y la degradación de tierras son problemas con repercusiones a nivel planetario, 
								por lo general son las comunidades más pobres y vulnerables las que se ven más
                afectadas por estos problemas debido a su dependencia del acceso a recursos naturales 
								para su subsistencia.`}
            </p>
            <p>
              {`Es por esta razón que el PPD se orienta particularmente al trabajo con comunidades,
                quienes son sus principales beneﬁciarios. De esta forma el PPD demuestra que las acciones 
								a nivel comunitario son una parte esencial de la solución de los problemas ambientales a 
								escala mundial.`}
            </p>
          </Col>
        </Row>
        <Row className="WhatIsPpd_container-images">
          <Col xs={12} md={4} className="text-center">
            <img src={fmam} alt="Fmam" />
          </Col>
          <Col xs={12} md={4} className="text-center">
            <img src={ppLogo} alt="Logo PPD" />
          </Col>
          <Col xs={12} md={4} className="text-center">
            <img src={pnud} alt="PNUD" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default WhatIsPpd;
