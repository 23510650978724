import React, { useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AppContext from "../../context/AppContext.js";
import "../../styles/components/dashboard/_unore.scss";
import Error from "../Error.jsx";
import Success from "../Success.jsx";

const initialState = {
  amount: "",
};

const Unore = () => {
  const [unore, setUnore] = useState(initialState);
  const [error, setError] = useState({ error: false, message: "" });
  const [success, setSuccess] = useState({ success: false, message: "" });

  const { saveUnore, state, logout } = useContext(AppContext);

  const handleChange = ({ target }) => {
    let priceUnore = target.value;

    if (priceUnore.match(/^\d*\.?\d*$/) !== null) {
      setUnore((state) => ({ ...state, amount: priceUnore }));
      setError({ error: false, message: "" });
    } else {
      setError({ error: true, message: "Solo se permiten números" });
    }
  };

  const updateUnore = async () => {
    if (unore.amount.trim() !== "") {
      let response = await saveUnore(unore);
      console.log(response);
      if (response === 201) {
        setUnore(initialState);
        setSuccess({
          success: true,
          message: "Precio actualizado correctamente",
        });
        setTimeout(() => {
          setSuccess({ success: false, message: "" });
        }, 3000);
      } else if (response === 401) {
        logout();
      } else {
        setError({
          error: true,
          message: "Error al actualizar el precio, intentelo de nuevo",
        });
      }
    } else {
      setError({ error: true, message: "El campo no puede estar vacío" });
    }
  };

  return (
    <Container>
      <Row className="Unore__container-main align-items-end">
        <Col xs={12} md={7} className="Unore__container-main--price my-3">
          <div className="">
            <p>
              {`Precio actual: `}
              {`${
                state.unore?.amount === undefined ? "0" : state.unore.amount
              } Bs/Dólar`}
            </p>
          </div>
        </Col>
        <Col xs={12} md={7} className="Unore__container-main--form my-3">
          <form onSubmit={(event)=>{ event.preventDefault()}}>
            <div className="form-group">
              <label htmlFor="unore">Unore: </label>
              <input
                type="text"
                id="unore"
                placeholder="Nuevo Precio"
                className="form-control"
                value={unore.amount}
                onChange={handleChange}
              />
            </div>
            <div className="form-group Unore__container-main--button">
              <button type="button" onClick={() => updateUnore()}>
                Actualizar
              </button>
            </div>
          </form>
          <div className="my-3">
            {error.error && <Error text={error.message} />}
            {success.success && <Success text={success.message} />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Unore;
