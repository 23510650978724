import React, { useContext } from "react";
import AppContext from "../../context/AppContext.js";
import "../../styles/components/news/_listNews.scss";
//react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

//components
import News from "./News.jsx";

//images
import buttonNews from "../../assets/news/botonNews.svg";

const ListNews = () => {
  const { state } = useContext(AppContext);


  return (
    <Container>
      <Row>
        <Col md={5} className="Allnews__container-title">
          <img src={buttonNews} alt="Noticias" />
        </Col>
        <Col md={7} className="Allnews__container-unore mb-5 mb-md-0">
          <p className="text-sm-start text-md-end">
            {`Tasa de cambio $ UNORE`}
            <span>{`${state.unore?.amount === undefined ? "0" : state.unore.amount} Bs/Dólar`}</span>
          </p>
        </Col>
      </Row>
      <Row>
        {state.news.map((news, index) => {
          return ( index <9 && <News key={`news-${news.id}`} news={news} />);
        })}
      </Row>
    </Container>
  );
};

export default ListNews;

