import React, { useContext } from "react";
import AppContext from "../context/AppContext";

import { Carousel } from "react-bootstrap";

// import campamentoUruyen from "../assets/banner/campamentoUruyen.jpg";
// import ecoturismoMerida from "../assets/banner/ecoturismoMerida.jpg";
// import granjaAutosustentable from "../assets/banner/granjaAutosustentable.jpg";
// import caficulturaFundaepekeina from "../assets/banner/caficulturaFundaepekeina.jpg";
// import liberacionComunitaria from "../assets/banner/liberacionComunitaria.jpg";
// import caficulturaTradicional from "../assets/banner/caficulturaTradicional.jpg";

const Banner = () => {
  const { state } = useContext(AppContext);

  return (
    <Carousel indicators={false}>
      {state.banners.length > 0 ? (
        state.banners.map((item) => {
          return (
            <Carousel.Item key={item.id}>
              <img
                className="d-block w-100 Carousel_img"
                src={item.image}
                alt={item.title}
              />
              <Carousel.Caption>
                <h3>{item.title}</h3>
                <p>{item.subtitle}</p>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })
      ) : (
        <div className="d-flex justify-content-center vh-100  align-items-center Banner__spiner">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </Carousel>
  );
};

export default Banner;
