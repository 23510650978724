import React, { useContext } from "react";
import AppContext from "../context/AppContext";

import { Container, Row, Col } from "react-bootstrap";

import botonUpdates from "../assets/updates/botonUpdates.svg";
import ppdVzlaUpdates from "../assets/updates/ppdVzlaUpdates.svg";
import ppdVzlaMovil from "../assets/updates/ppdVzlaMovil.svg";

const Updates = () => {
  const { state } = useContext(AppContext);
  const { updates } = state;
  return (
    <Container fluid={true} className="Updates_container mt-5">
      <Container>
        <Row className="Update_info-container justify-content-center justify-items-center">
          <Col xs={12} className="d-flex justify-content-end">
            <img
              src={botonUpdates}
              alt="Actualizaciones"
              className="Updates_img-updates"
            />
          </Col>

          <Col xs={12} className="d-flex justify-content-center">
            <img
              src={ppdVzlaUpdates}
              className="Update_img-ppd d-none d-md-block"
              alt="PPD-Venezuela"
            />
            <img
              src={ppdVzlaMovil}
              className="Update_img-ppd d-md-none"
              alt="PPD-Venezuela"
            />
          </Col>
          {updates.map((item) => {
            return (
              <Col xs={12} md={5} key={item.id}>
                <div >
                  <label>{item.amount}</label>
                  <p>{item.name}</p>
                  <p>{item.description}</p>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </Container>
  );
};

export default Updates;
