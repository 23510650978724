import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import AppContext from "../../context/AppContext.js";
import { Col, Container, Row } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const initialState = {
  title: "",
  subtitle: "",
  summary: "",
  complete: "",
  highlighted: "",
  image_preview: "",
  image: "",
  image_secondary: "",
};

const initialError = {
  title: false,
  image: false,
  complete: false,
  summary: false,
  image_preview: false,
};

const UpdateNews = () => {
  const [editNews, setEditNews] = useState(initialState);
  const [error, setError] = useState(initialError);
  const form = useRef(null);

  const navigate = useNavigate();
  const { id } = useParams();

  const { state, updateNews } = useContext(AppContext);
  const { title, complete } = editNews;

  const searchNews = useCallback(() => {
    const news = state.news.find((news) => news.id === Number(id));
    if (!news) return navigate("/administrador/news");
    setEditNews(news);
  }, [state.news, id, navigate]);

  const handleSubmit = async (e) => {
    if (title.trim() === "" || complete.trim() === "") {
      setError({
        ...error,
        title: true,
        complete: true,
      });
      return;
    }

    const formData = new FormData(form.current);
    formData.append("id", Number(id));
    formData.append("title", editNews.title);
    formData.append("subtitle", editNews.subtitle);
    formData.append("summary", editNews.summary);
    formData.append("highlight", editNews.highlight);
    formData.append("image", editNews.image);
    formData.append("image_secondary", editNews.image_secondary);
    formData.append("image_preview", editNews.image_preview);
    formData.append("complete", editNews.complete);

    const response = await updateNews(formData);

    if (response === 401) {
      navigate("/administrador");
    }
    if (response === 201) {
      setError(initialError);
      Swal.fire(
        "Noticia Actualizada!",
        "La noticia se actualizó correctamente",
        "success"
      ).then(() => {
        navigate("/administrador/news");
      });
    }

    if (response === 500) {
      Swal.fire("Error!", "Hubo un error al actualizar la noticia", "error");
    }
  };

  const handleChanges = ({ target }) => {
    setEditNews({
      ...editNews,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    searchNews();
  }, [searchNews, id]);

  return (
    <Container>
      <Row className="justify-content-center mb-4">
        <Col xs={12} md={8} lg={7} xl={6} className="UpdateNews__container-col">
          <h1>Actualizar Noticia</h1>
          <form ref={form}>
            <div className="form-group">
              <label htmlFor="titleMain">Título principal</label>
              <textarea
                type="text"
                id="titleMain"
                className="form-control"
                name="title"
                value={editNews?.title}
                onChange={handleChanges}
              ></textarea>
              {error.title && (
                <p className="text-danger">El campo es obligatorio</p>
              )}
            </div>

            <div className="form-group my-3">
              <label htmlFor="subtitle">Subtítulo</label>
              <textarea
                type="text"
                id="subtitle"
                className="form-control"
                name="subtitle"
                value={editNews?.subtitle}
                onChange={handleChanges}
              ></textarea>
            </div>

            <div className="form-group my-3">
              <label htmlFor="summary">Resumen</label>
              <textarea
                type="text"
                id="summary"
                className="form-control"
                name="summary"
                value={editNews?.summary}
                onChange={handleChanges}
              ></textarea>
            </div>

            <div className="form-group my-3">
              <label htmlFor="destacado">Destacado</label>
              <textarea
                type="text"
                id="destacado"
                className="form-control"
                name="highlighted"
                value={editNews?.highlighted}
                onChange={handleChanges}
              ></textarea>
            </div>

            <div className="form-group my-3">
              <label htmlFor="news">Noticia</label>
              <textarea
                type="text"
                id="news"
                className="form-control"
                name="complete"
                value={editNews?.complete}
                onChange={handleChanges}
              ></textarea>
              {error.complete && (
                <p className="text-danger">El campo es obligatorio</p>
              )}
            </div>

            <div className="form-group my-3 UpdateNews__form--image">
              <div>
                {!editNews?.image ? (
                  <p>No tenemos imagen cargada</p>
                ) : (
                  <img src={editNews?.image} alt={editNews?.title} />
                )}
              </div>
              <label htmlFor="image">Imagen Principal:</label>
              <input
                type="file"
                id="image"
                className="form-control"
                name="image"
              ></input>
            </div>

            <div className="form-group my-3  UpdateNews__form--image">
              <div>
                {!editNews?.image_secondary ? (
                  <p>No tenemos imagen cargada</p>
                ) : (
                  <img src={editNews?.image_secondary} alt={editNews?.title} />
                )}
              </div>
              <label htmlFor="image_secondary">Imagen Secundaria:</label>
              <input
                type="file"
                id="image_secondary"
                className="form-control"
                name="image_secondary"
              ></input>
            </div>

            <div className="form-group my-3 UpdateNews__form--image">
              <div>
                {!editNews?.image_preview ? (
                  <p>No tenemos imagen cargada</p>
                ) : (
                  <img src={editNews?.image_preview} alt={editNews?.title} />
                )}
              </div>

              <label htmlFor="image_preview">Imagen de vista previa:</label>
              <input
                type="file"
                id="image_preview"
                className="form-control"
                name="image_preview"
              ></input>
            </div>

            <div className="UpdateNews__container-col--button border">
              <button type="button" onClick={() => handleSubmit()}>
                Actualizar
              </button>

              <button
                type="button"
                onClick={() => navigate("/administrador/news")}
              >
                Volver
              </button>
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default UpdateNews;
