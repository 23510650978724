import React from "react";
import "../styles/components/_footer.scss";

import {Container, Row, Col} from 'react-bootstrap';

const Footer = ({bgColor}) => {
  return (
    <Container fluid={true} className={`${bgColor}`}>
      <Container>
        <Row>
          <Col>
            <p className="Footer_paragraph">
              Copyright &copy; :: PPD Venezuela todos los derechos reservados
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
