import React, { useState, useContext } from "react";
import "../styles/components/_login.scss";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import AppContext from "../context/AppContext";
import Error from "./Error.jsx";

const initialState = {
  email: "",
  password: "",
};

const Login = () => {
  const { login } = useContext(AppContext);
  const [user, setUser] = useState(initialState);
  const [error, setError] = useState({ error: false, message: "" });
  const { email, password } = user;

  const navigate = useNavigate();

  const handleChange = ({ target }) => {
    setUser({
      ...user,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async () => {
    if (email.trim() === "" || password.trim() === "") {
      setError({ error:true, message: "Todos los campos son obligatorios" });
      return;
    }else{
       let response = await login(user);
    if (response) {
      navigate("/administrador/unore");
      return;
    } else {
      setError({ error: true, message: "Usuario o contraseña incorrectos" });
      return;
    }
    }
   
  };
  
  return (
    <Container fluid={true} className="Login__container-main ">
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5} xxl={4} className="mt-5">
          <h1 className="Login--title">Panel de Admnistración</h1>
          <form className="Login__form-login ">
            <div className="form-group">
              <label htmlFor="usuario">Usuario:</label>
              <input
                type="text"
                id="usuario"
                className="form-control "
                name="email"
                value={email}
                onChange={handleChange}
              />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="password">Contraseña:</label>
              <input
                type="password"
                id="password"
                className="form-control "
                name="password"
                value={password}
                onChange={handleChange}
              />
            </div>

            <div className="form-group mt-3 ">{error.error && <Error text={error.message} />}</div>

            <div className="Login__container-button form-group my-3">
              <button type="button" onClick={() => handleSubmit()}>
                Ingresar
              </button>
              {/* <p>Registrar User</p>
              <p>Olvide contraseña</p> */}
            </div>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
