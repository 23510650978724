import React from "react";
import { Link } from "react-router-dom";
import "../../styles/components/news/_news.scss"

//bootstrap
import { Col } from "react-bootstrap";

const News = ({ news }) => {
  return (
    <Col
      className="news__container d-flex justify-content-center my-3"
      xs={12}
      md={6}
      lg={4}
      key={news.id}
    >
      <div className="news__container--news">
        <Link to={`/noticias/${news.id}`}>
          <div>
          <p>{news.summary}</p>
          <img src={news.image_preview} alt={``} />
          </div>
        </Link>
      </div>
    </Col>
  );
};

export default News;