import React, { useContext, useRef, useState } from "react";
import AppContext from "../../context/AppContext";
import Swal from "sweetalert2";
import Error from "../Error";
import "../../styles/components/dashboard/_innovationDash.scss";
import { Container, Row, Col } from "react-bootstrap";

const initialState = {
  description: "",
  nameFile: "",
  innovationImage: "",
};

function InnovationDash() {
  const { state, createdInnovation, deleteInnovation } = useContext(AppContext);
  const [formInnovation, setFormInnovation] = useState(initialState);
  const [error, setError] = useState({ error: false, message: "" });
  const form = useRef(null);

  const handleChange = ({ target }) => {
    setFormInnovation({
      ...formInnovation,
      [target.name]: target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      formInnovation.description.trim() === "" ||
      formInnovation.nameFile.trim() === "" ||
      formInnovation.innovationImage.trim() === ""
    ) {
      setError({
        error: true,
        message: "Todos los campos son obligatorios",
      });
      return;
    }

    const formData = new FormData(form.current);
    formData.append("description", formInnovation.description);
    formData.append("nameFile", formInnovation.nameFile);
    formData.append("innovationImage", formInnovation.innovationImage);

    const response = await createdInnovation(formData);

    if (
      response === 400 ||
      response === 401 ||
      response === 403 ||
      response === 404 ||
      response === 500
    ) {
      Swal.fire("Error!", "Hubo un error al cagar el banner", "error");
    }

    if (response === 201) {
      setFormInnovation(initialState);
      setError({ error: false, message: "" });
      Swal.fire("Creado!", "Innovación creada con éxito", "success");
    }
  };


  const handleDelete = async (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, borralo!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteInnovation(id).then((response) => {
          if (
            response === 400 ||
            response === 401 ||
            response === 404 ||
            response === 500
          ) {
            Swal.fire("Error!", "Ha ocurrido un error.");
          }
          if (response === 204) {
            Swal.fire("Borrado!", "El banner ha sido borrado.");
          }
        });
      }
    });
  }

  return (
    <Container>
      <Row>
        {state.innovation.map((item) => {
          return (
            <Col xs={12} md={7} key={item.id} className="InnovationDash__col--info py-3">
              <div>
                <img src={item.url_imgage} alt={item.title} />
              </div>
              <div>
                <p>
                  <span>Id:</span>
                  {item.id}
                </p>
                <p>
                  <span>Título:</span>
                  {item.title}
                </p>
                <p>
                  <span>Nombre del archivo:</span>
                  {item.url_file}
                </p>
                <div>
                  <button onClick={()=>handleDelete(item.id)}>Borrar</button>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>

      <Row>
        <Col xs={12} md={7} className="mb-5 Banner_container--form">
          <h2>Cargar innovación</h2>
          <form className="mb-2 " ref={form}>
            <div className="form-group">
              <label htmlFor="description">Descripción</label>
              <input
                type="text"
                className="form-control"
                id="description"
                placeholder="Descripción"
                name="description"
                value={formInnovation.description}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="nameFile">Nombre</label>
              <input
                type="text"
                className="form-control"
                id="nameFile"
                placeholder="Nombre del archivo"
                name="nameFile"
                value={formInnovation.nameFile}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="innovationImage">Imagen de innovación</label>
              <input
                type="file"
                className="form-control"
                id="innovationImage"
                placeholder="Cargar una imagen"
                name="innovationImage"
                onChange={handleChange}
              />
            </div>

            <div>
              <button onClick={handleSubmit}>Guardar</button>
            
            </div>
          </form>
          {error.error && <Error text={error.message} />}
        </Col>
      </Row>
    </Container>
  );
}

export default InnovationDash;
