import React, { useState, useContext } from "react";
import "../../styles/components/dashboard/_actualizaciones.scss";
import AppContext from "../../context/AppContext";
import Error from "../Error";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Container, Row, Col } from "react-bootstrap";

const initialState = {
  amount: "",
  name: "",
  description: "",
};

const UpdateActualizacion = () => {
  const [actualizacion, setActualizacion] = useState(initialState);
  const [error, setError] = useState({ error: false, message: "" });

  const { updateActualizacion } = useContext(AppContext);

  const { amount, name, description } = actualizacion;

  const { id } = useParams();
  const navigate = useNavigate();

  const handleChange = (event) => {
    setActualizacion({
      ...actualizacion,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.preventDefault();
    if (
      amount.trim() === "" ||
      name.trim() === "" ||
      description.trim() === ""
    ) {
      setError({
        error: true,
        message: "Todos los campos son obligatorios",
      });
      return;
    }

    const response = await updateActualizacion(actualizacion, id);
    if (response) {
      setActualizacion(initialState);
      setError(false);
      Swal.fire(
        "Actualización exitosa!",
        "La actualización se creó correctamente",
        "success"
      ).then(() => {
        navigate("/administrador/actualizacion");
      });
    }
    if (response === 400) {
      Swal.fire("Error!", "Hubo un error en la actualización", "error");
    }
    if (response === 500) {
      Swal.fire("Error!", "Hubo un error en la actualización", "error");
    }
  };

  return (
    <Container>
      <Row className="">
        <Col xs={12} md={6} className="Actualizaciones__col--form">
          <h2>Actualizaciones </h2>
          <form className="mb-3">
            <div className="form-group mb-3">
              <label>Cantidad</label>
              <input
                className="form-control"
                type="text"
                name="amount"
                value={actualizacion.amount}
                onChange={handleChange}
                placeholder="Ingresar Cantidad"
              />
            </div>
            <div className="form-group mb-3">
              <label>Nombre</label>
              <input
                className="form-control"
                type="text"
                name="name"
                value={actualizacion.name}
                onChange={handleChange}
                placeholder="Ingresar Nombre"
              />
            </div>
            <div className="form-group mb-1">
              <label>Descripción</label>
              <input
                className="form-control"
                type="text"
                name="description"
                value={actualizacion.description}
                onChange={handleChange}
                placeholder="Ingresar Descripción"
              />
            </div>
            <div></div>
            <div className="form-group">
              <button onClick={(event) => handleSubmit(event)}>Guardar</button>
            </div>
          </form>
          {error.error && <Error text={error.message} />}
        </Col>
      </Row>
    </Container>
  );
};

export default UpdateActualizacion;
