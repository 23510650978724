import React from 'react';
import '../styles/components/_error.scss';

const Error = (props) => {
  const {text} = props;
  return (
    <div className='alert alert-danger Error__container'>
      <p className='m-2'>{text}</p>
    </div>
  );
};

export default Error;