import React from "react";
import { Helmet } from "react-helmet";
//components
import ProjectPortfolio from "../components/ProjectPortfolio";
import ProjectMap from "../components/ProjectMap.jsx";
import Footer from "../components/Footer.jsx";
import CountriesWork from "../components/CountriesWork.jsx";

const Projects = () => {
  document.body.className = "";
  return (
    <>
			<Helmet>
				<title>PPD - Proyectos</title>
			</Helmet>
      <ProjectPortfolio />
      <ProjectMap />
      <CountriesWork />
      <Footer bgColor="Footer_bg-footer-orange" />
    </>
  );
};

export default Projects;
