import React, { useState } from "react";
import "../styles/components/_menu.scss";
import { Link, useLocation } from "react-router-dom";

import { Navbar, Nav } from "react-bootstrap";

const Menu = () => {
  const [expanded, setExpanded] = useState(false);

  const location = useLocation().pathname;
  const getLinkHome = (path) => {
    if (location === path) {
      return "Menu_link-active-home";
    } else {
      return "";
    }
  };
  const getLinkAbout = (path) => {
    if (location === path) {
      return "Menu_link-active-about";
    } else {
      return "";
    }
  };
  const getLinkParticipate = (path) => {
    if (location === path) {
      return "Menu_link-active-participate";
    } else {
      return "";
    }
  };
  const getLinkProjects = (path) => {
    if (location === path) {
      return "Menu_link-active-projects";
    } else {
      return "";
    }
  };
  const getLinkDownload = (path) => {
    if (location === path) {
      return "Menu_link-active-download";
    } else {
      return "";
    }
  };

  return (
    <>
      <Navbar expanded={expanded} expand="md" className="Menu_navbar d-md-none">
        <Navbar.Brand className="Menu_brand"></Navbar.Brand>

        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : "expanded")}
        >
          <div className="Menu_burger-div-one"></div>
          <div className="Menu_burger-div-two"></div>
          <div className="Menu_burger-div-three"></div>
        </Navbar.Toggle>

        <Navbar.Collapse className="Menu_collapse ">
          <Nav className="Menu_nav">
            <ul className="navbar-nav w-100 Menu_small-ul">
              <li className="">
                <Link
                  to="/"
                  className={`Menu_link-anchor-home Menu_link-hover-home ${getLinkHome(
                    "/"
                  )}`}
                  onClick={() => setExpanded(false)}
                >
                  {"Inicio"}
                </Link>
              </li>
              <li className="">
                <Link
                  to="/quienes-somos"
                  className={`Menu_link-anchor-about Menu_link-hover-about ${getLinkAbout(
                    "/quienes-somos"
                  )}`}
                  onClick={() => setExpanded(false)}
                >
                  {"Quienes Somos"}
                </Link>
              </li>
              <li className="">
                <Link
                  to="/participa"
                  className={`Menu_link-anchor-participate Menu_link-hover-participate ${getLinkParticipate(
                    "/participa"
                  )}`}
                  onClick={() => setExpanded(false)}
                >
                  {"Participa"}
                </Link>
              </li>
              <li className="">
                <Link
                  to="/proyectos"
                  className={`Menu_link-anchor-projects Menu_link-hover-projects ${getLinkProjects(
                    "/proyectos"
                  )}`}
                  onClick={() => setExpanded(false)}
                >
                  {"Proyectos"}
                </Link>
              </li>
              <li className="">
                <Link
                  to="/descargas"
                  className={`Menu_link-anchor-download Menu_link-hover-download ${getLinkDownload(
                    "/descargas"
                  )}`}
                  onClick={() => setExpanded(false)}
                >
                  {"Descargas"}
                </Link>
              </li>
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <Navbar
        expanded={expanded}
        expand="sm"
        className="Menu_navbar d-none d-md-flex"
      >
        <Navbar.Brand className="Menu_brand"></Navbar.Brand>

        <Navbar.Toggle
          onClick={() => setExpanded(expanded ? false : "expanded")}
        ></Navbar.Toggle>

        <Navbar.Collapse className="Menu_collapse ">
          <Nav className="Menu_nav m-auto">
            <ul className="navbar-nav Menu_large-ul">
              <li className="">
                <Link
                  to="/"
                  className={`Menu_link-anchor-home Menu_link-hover-home ${getLinkHome(
                    "/"
                  )}`}
                  onClick={() => setExpanded(false)}
                >
                  {"Inicio"}
                </Link>
              </li>
              <li className="">
                <Link
                  to="/quienes-somos"
                  className={`Menu_link-anchor-about Menu_link-hover-about ${getLinkAbout(
                    "/quienes-somos"
                  )}`}
                  onClick={() => setExpanded(false)}
                >
                  {"Quienes Somos"}
                </Link>
              </li>
              <li className="">
                <Link
                  to="/participa"
                  className={`Menu_link-anchor-participate Menu_link-hover-participate ${getLinkParticipate(
                    "/participa"
                  )}`}
                  onClick={() => setExpanded(false)}
                >
                  {"Participa"}
                </Link>
              </li>
              <li className="">
                <Link
                  to="/proyectos"
                  className={`Menu_link-anchor-projects Menu_link-hover-projects ${getLinkProjects(
                    "/proyectos"
                  )}`}
                  onClick={() => setExpanded(false)}
                >
                  {"Proyectos"}
                </Link>
              </li>
              <li className="">
                <Link
                  to="/descargas"
                  className={`Menu_link-anchor-download Menu_link-hover-download ${getLinkDownload(
                    "/descargas"
                  )}`}
                  onClick={() => setExpanded(false)}
                >
                  {"Descargas"}
                </Link>
              </li>
            </ul>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Menu;
