import React from "react";
import '../styles/components/_ppdVenezuela.scss'

import ppdInVenezuela from "../assets/ppdInVenezuela/ppdInVenezuela.svg";

import { Container, Row, Col } from "react-bootstrap";

const PpdVenezuela = () => {
  return (
    <Container className="PpdVenezuela_container-main">
      <Row>
        <Col xs={12} className="PpdVenezuela_image-ppdVenezuela text-right">
          <img src={ppdInVenezuela} alt="PPD en Venezuela" />
        </Col>
        <Col className="PpdVenezuela_paragraph">
          <p>
            {`Las primeras donaciones del PPD en Venezuela se realizaron en 2010, durante la
							cuarta fase operacional (OP4) del FMAM. Sin embargo, el proceso de incorporación
							del país al Programa fue iniciado en 2004, cuando el Ministerio del Ambiente y de los
							Recursos Naturales (actualmente Ministerio del Poder Popular para el Ecosocialismo) y
							un grupo signiﬁcativo de ONG ambientalistas venezolanas mostraron su interés en la
							participación de Venezuela en el Programa.`}
          </p>
          <p>
            {`En 2008 el Punto Focal Operativo del FMAM, representado por el Ministerio del Poder
							Popular para el Ambiente, presentó a discusión la implementación del Programa de
							Pequeñas Donaciones en Venezuela. Con esta decisión se resolvió dar inicio al proceso
							formal de inclusión de Venezuela en esta iniciativa, asignando fondos del FMAM para
							apalancar proyectos locales en las áreas de Biodiversidad y Cambio Climático, a las que
							posteriormente, en 2012, se sumó el área Desertiﬁcación y Degradación de Suelos.`}
          </p>
          <p>
            {`Desde 2010, el PPD Venezuela ha brindado apoyo a más de 188 proyectos comunitarios 
						en diferentes estados del país, por un monto de USD 5,241,812.96.`}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PpdVenezuela;
