import React, { useState, useContext } from "react";
import "../styles/components/_contactForm.scss";
import AppContext from "../context/AppContext";
import Swal from "sweetalert2";
import Success from "./Success.jsx";
import Error from "./Error.jsx";

import { Container, Row, Col } from "react-bootstrap";

import buttonContact from "../assets/formContact/buttonContact.svg";

const initialState = {
  name: "",
  organization: "",
  emailAddress: "",
  subject: "",
  message: "",
};

const initialError = {
  name: false,
  organization: false,
  emailAddress: false,
  subject: false,
  message: false,
};

const ContactForm = () => {
  const [contact, setContact] = useState(initialState);
  const [error, setError] = useState(initialError);
  const [sendError, setSendError] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const { sendEmailContact } = useContext(AppContext);
  const { name, organization, emailAddress, subject, message } = contact;

  const handleChange = ({ target }) => {
    setContact(() => ({
      ...contact,
      [target.name]: target.value,
    }));
  };

  const handleSendForm = async () => {
    if (
      name.trim() === "" ||
      emailAddress.trim() === "" ||
      subject.trim() === "" ||
      message.trim() === "" ||
      organization.trim() === ""
    ) {
      setError({
        ...error,
        name: true,
        emailAddress: true,
        subject: true,
        message: true,
        organization: true,
      });
      return;
    }

    const result = await sendEmailContact(contact);
    if (result === 200) {
      setContact(initialState);
      setError(initialError);
      setSendSuccess(true);
      setTimeout(() => {
        setSendSuccess(false)
      }, 3000);
    }else{
      setSendError(true);
      setTimeout(() => {
        setSendError(false)
      }, 10000);
    }
   
  };

  return (
    <Container className="mt-3 mb-5">
      <Row>
        <Col xs={12} md={6} className="FormContact_col-data">
          <img
            src={buttonContact}
            className="FormContact_img-contact"
            alt="Contacto"
          />

          <div>
            <p className="FormContact_description">Alexis Bermúdez</p>
            <label className="FormContact_label">Coordinador Nacional</label>
          </div>

          <div>
            <label className="FormContact_label">Teléfono</label>
            <p className="FormContact_description">{`+58 (212) 208 4444`}</p>
          </div>

          <div>
            <label className="FormContact_label">Correo Electrónico</label>
            <p className="FormContact_description">{`alexis.bermudez@undp.org`}</p>
          </div>

          <div>
            <label className="FormContact_label">Dirección</label>
            <p className="FormContact_description">
              {`Avenida Francisco de Miranda, Torre HP, Piso 6, oficina 6-A.
                            Los Palos Grandes, Municipio Chacao.
                            Distrito Capital.
                            Zona Postal 1060.
                            `}
            </p>
          </div>

          <div>
            <label className="FormContact_label">Horario</label>
            <p className="FormContact_description">
              Lunes a Jueves: 9:00 am - 6:00 pm
            </p>
            <p className="FormContact_description">
              Viernes: 9:00 am - 1:00 pm
            </p>
          </div>
        </Col>

        <Col xs={12} md={6} className="FormContact_col-form">
          <div className="FormContact_title-form">
            {/* <h1>Redes sociales</h1> */}
            <h2>Formulario de Contacto</h2>
          </div>
          <form className="FormContact_form">
            <div className="form-group">
              <label className="FormContact_form-label">
                {`Tú Nombre (requerido)`}
              </label>
              <input
                className="form-control"
                name="name"
                type="text"
                value={name}
                onChange={handleChange}
              />
              {error.name && (
                <p className="text-danger">El campo es obligatorio</p>
              )}
            </div>
            <div className="form-group">
              <label className="FormContact_form-label">{`Organización`}</label>
              <input
                className="form-control"
                name="organization"
                type="text"
                value={organization}
                onChange={handleChange}
              />
              {error.organization && (
                <p className="text-danger">El campo es obligatorio</p>
              )}
            </div>

            <div className="form-group">
              <label className="FormContact_form-label">
                {`Tú Correo Electronico`}{" "}
              </label>
              <input
                className="form-control"
                name="emailAddress"
                type="email"
                value={emailAddress}
                onChange={handleChange}
              />
              {error.emailAddress && (
                <p className="text-danger">El campo es obligatorio</p>
              )}
            </div>

            <div className="form-group">
              <label className="FormContact_form-label">{`Asunto`} </label>
              <input
                className="form-control"
                name="subject"
                type="text"
                value={subject}
                onChange={handleChange}
              />
              {error.subject && (
                <p className="text-danger">El campo es obligatorio</p>
              )}
            </div>

            <div className="form-group">
              <label className="FormContact_form-label">{`Mensaje`} </label>
              <textarea
                className="form-control"
                name="message"
                value={message}
                onChange={handleChange}
              />
              {error.message && (
                <p className="text-danger">El campo es obligatorio</p>
              )}
            </div>

            <div className="text-center mt-4">
              <button type="button" onClick={() => handleSendForm()}>
                Enviar
              </button>
            </div>
          </form>
          <div className="my-3">
            {sendError && (
              <Error text="Su email no se envio, intente de nuevo" />
            )}
            {sendSuccess && <Success text="Su email se envio correctamente" />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;
