import React, { useEffect, useContext, useState } from "react";
import AppContext from "../../context/AppContext.js";
import { Container, Row, Col } from "react-bootstrap";

const Users = () => {
  const { state, getAllUsers } = useContext(AppContext);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAllUsers();
    setUsers(state.users);
  }, []);

  return (
    <>
      <Container>
        <Row>
          {users.map((item) => {
            return (
              <Col
                xs={12}
                key={item.id}
                className="d-flex flex-column flex-md-row py-2 justify-content-between Users__container-allusers my-2"
              >
                <div className="d-flex">
                  <p>{item.id}</p>
                  <p>{item.email}</p>
                </div>
                <div>
                  <button>Eliminar</button>
                  <button>Resetear Password</button>
                </div>
              </Col>
            );
          })}
        </Row>
        <Row className="mt-4 Users__container">
          <Col xs={12}>
            <h2>Crear usuarios:</h2>
          </Col>
          <Col
            xs={12}
            md={8}
            lg={7}
            xl={6}
            className="SaveNews__container-col my-3"
          >
            <form>
              <div className="form-group">
                <label id="emailId">Correo:</label>
                <input
                  type="text"
                  id="emailId"
                  placeholder="Correo electronico"
                  className="form-control"
                  name="title"
                />
                {/* {error.title && (
                <p className="text-danger">El campo es obligatorio</p>
              )} */}
              </div>
            </form>
          </Col>

          {/* "email":"adjbermudez@gmil.com",
  "fullname":"Deimian Vásquez",
  "password":"Pen.2711",
  "rol":"ADMINISTRATOR" */}
        </Row>
      </Container>
    </>
  );
};

export default Users;
