import React from "react";
import '../styles/components/_formulateProposal.scss';

//react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

//images
import buttonHowFormulateProposal from "../assets/formulateProposal/buttonHowToFormulateProposal.svg";
import arrowGreenDown from "../assets/formulateProposal/arrowGreenDown.svg";
import arrowGreenUp from "../assets/formulateProposal/arrowGreenUp.svg";
import arrowBlueDown from "../assets/formulateProposal/arrowBlueDown.svg";
import arrowOrangeUp from "../assets/formulateProposal/arrowOrangeUp.svg";
import arrowOrangeDown from "../assets/formulateProposal/arrowOrangeDown.svg";

const FormulateProposal = () => {
	return (
		<Container className="FormulatePropostal_container-main">
			<Row>
				<Col xs={12} className="FormulatePropostal_title-image text-right">
					<img
						src={buttonHowFormulateProposal}
						alt="Como formular una propuesta"
					/>
				</Col>

				<Col xs={12} className="FormulateProposal_container-proposal-success">
					<p>
						{`
              Cómo formular con éxito una Propuesta de Proyecto del PPD:
            `}
					</p>
				</Col>
				<Col xs={12} md={4} className="my-3">
					<div className="FormulateProposal_step-one p-0 text-center ">
						<div>
							<span>{`1`}</span>
						</div>
						<div>
							<span>{`Diagnóstico`}</span>
						</div>
						<div>
							<span>{`Causas`}</span>
						</div>
						<div>
							<img src={arrowGreenUp} alt="Arrow Up" />
						</div>
						<div>
							<span>
								{`¿Qué problema
                ambiental
                afecta a la
                Comunidad?`}
							</span>
						</div>
						<div>
							<span>
								{`(Revisar Estrategia
                Nacional PPD Venezuela 
								para ver si es elegible)`}
							</span>
						</div>
						<div>
							<img src={arrowGreenDown} alt="Arrow Down" />
						</div>
						<div>
							<span>{`Consecuencias`}</span>
						</div>
					</div>
				</Col>
				<Col xs={12} md={4} className="my-3">
					<div className="FormulateProposal_step-two p-0 text-center">
						<div>
							<span>{`2`}</span>
						</div>
						<div>
							<span>{`Planiﬁcación`}</span>
						</div>
						<div>
							<span>
								{`¿Qué podemos
								hacer en la
								comunidad
								para resolver
								el problema?`}
							</span>
						</div>
						<div>
							<img src={arrowBlueDown} alt="Arrow Down" />
						</div>
						<div>
							<span>{`Objetivos`}</span>
						</div>
						<div>
							<img src={arrowBlueDown} alt="Arrow Down" />
						</div>
						<div>
							<span>{`Resultados`}</span>
						</div>
						<div>
							<img src={arrowBlueDown} alt="Arrow Down" />
						</div>
						<div>
							<span>{`Actividades`}</span>
						</div>
					</div>
				</Col>
				<Col xs={12} md={4} className="my-3">
					<div className="FormulateProposal_step-three p-0 text-center">
						<div>
							<span>{`3`}</span>
						</div>
						<div>
							<span>{`Beneficios`}</span>
							<br />
							<span>{`Potenciales`}</span>
						</div>
						<div>
							<span>
								{`Fortalecimiento de
								capacidades`}
							</span>
						</div>
						<div>
							<img src={arrowOrangeUp} alt="Arrow Up" />
						</div>
						<div>
							<span>
								{`¿Qué ganamos
								en la comunidad con este
								proyecto?`}
							</span>
						</div>
						<div>
							<img src={arrowOrangeDown} alt="Arrow Down" />
						</div>
						<div>
							<span>
								{`Actividades
								socioproductivas`}
							</span>
						</div>
						<div>
							<span>{`(Medios de vida)`}</span>
						</div>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default FormulateProposal;
