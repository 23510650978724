import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppContext from "../context/AppContext.js";
import useInitialState from "../hooks/useInitialState.js";
import Layout from "../components/Layout.jsx";
import Home from "../pages/Home.jsx";
import DetailNews from "../components/news/DetailNews.jsx";
import NotFound from "../pages/NotFound.jsx";
import AboutUs from "../pages/AboutUs.jsx";
import Participate from "../pages/Participate.jsx";
import Projects from "../pages/Projects.jsx";
import Downloads from "../pages/Downloads.jsx";
import Dashboard from "../pages/Dashboard.jsx";
import Unore from "../components/dashboard/Unore.jsx";
import SaveNews from "../components/dashboard/SaveNews.jsx";
import Users from "../components/dashboard/Users.jsx";
import UpdateNews from "../components/dashboard/UpdateNews.jsx";
import Actualizaciones from "../components/dashboard/Actualizaciones.jsx";
import UpdateActualizacion from "../components/dashboard/UpdateActualizacion.jsx";
import CreateBanner from "../components/dashboard/CreateBanner.jsx";
import InnovationDash from "../components/dashboard/InnovationDash.jsx";

const App = () => {
  const initialState = useInitialState();

  return (
    <AppContext.Provider value={initialState}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/noticias/:newsId" element={<DetailNews />} />
            <Route path="/participa" element={<Participate />} />
            <Route path="/proyectos" element={<Projects />} />
            <Route path="/descargas" element={<Downloads />} />
            <Route path="/quienes-somos" element={<AboutUs />} />
            <Route path="/administrador" element={<Dashboard />}>
              <Route path="unore" element={<Unore />} />
              <Route path="news" element={<SaveNews />} />
              <Route path="users" element={<Users />} />
              <Route path="editar-noticia/:id" element={<UpdateNews />} />
              <Route path="actualizacion" element={<Actualizaciones />} />
              <Route
                path="editar-actualizaciones/:id"
                element={<UpdateActualizacion />}
              />
              <Route path="banner" element={<CreateBanner />} />
              <Route path="innovation" element={<InnovationDash />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default App;
