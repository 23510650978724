import React from "react";
import '../styles/components/_focalAreas.scss';

//react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

//images
import areasFocales from "../assets/focalAreas/areasFocales.svg";
import mediosVidasSustentables from "../assets/focalAreas/mediosVidasSustentables.png";

const FocalAreas = () => {
	return (
		<Container className="FocalAreas_container-main">
			<Row>
				<Col xs={12} className="FocalAreas_title-imagen text-right">
					<img src={areasFocales} alt="Áreas Focales" />
				</Col>

				<Col xs={12} className="FocalAreas_text" >
					<p>
            {`
              En 2008 el Punto Focal Operativo del FMAM, representado por el Ministerio del Poder
              Popular para el Ambiente, presentó a discusión la implementación del Programa de
              Pequeñas Donaciones en Venezuela. Con esta decisión se resolvió dar inicio al proceso
              formal de inclusión de Venezuela en esta iniciativa, asignando fondos FMAM para apalancar 
							proyectos locales en las áreas de Biodiversidad y Cambio Climático, a las que
              posteriormente, en 2012, se sumó el área Desertiﬁcación y Degradación de Suelos.
            `}
					</p>
				</Col>

        <Col xs={12} className="FocalAreas_image-sustainable text-center">
          <img src={mediosVidasSustentables} alt="Medios de vida sustentables" />
        </Col>
			</Row>
		</Container>
	);
};

export default FocalAreas;
