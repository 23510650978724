import React, { useContext } from "react";
import "../styles/components/_innovation.scss";
import AppContext from "../context/AppContext";

//images
import buttonInnovation from "../assets/innovation/buttonInnovation.svg";

//react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

const Innovation = () => {
  const { state } = useContext(AppContext);

  return (
    <Container fluid={true} className="Innovation_container-main">
      <Container>
        <Row>
          <Col className="Innovation_container-innovation-img">
            <img src={buttonInnovation} alt="Inovacion" />
          </Col>
        </Row>
        <Row className={`Innovation_container-books mt-4`}>
          {state.innovation &&
            state.innovation.map((item) => {
              return (
                <Col key={item.id} xs={12} md={4} className="text-center">
                  <a
                    href={`https://ppdvenezuela.org/documentos/pdfs/${item.url_file}.pdf`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={`${item.url_imgage}`} alt={item.title} />
                    <p>{`${item.title}`}</p>
                  </a>
                </Col>
              );
            })}
        </Row>
      </Container>
    </Container>
  );
};

export default Innovation;
