import React from 'react'
import '../styles/components/_developmentGoal.scss';

//react-bootstrap
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';

//images
import developmentGoal from '../assets/developmentGoal/developmentGoal.png';
import developmentGoalTitle from '../assets/developmentGoal/developmentGoalTitle.svg';


const DevelopmentGoal =()=>{
  return(
    <Container>
      <Row className="mt-5">
        <Col className="developmentGoal_title-container text-right">
          <img src={developmentGoalTitle} alt="Objetivo de desarrollo sostenible" />
        </Col>
      </Row>

      <Row className="justify-content-center my-3">
        <Col md={10} lg={7} className="developmentGoal_image-development">
          <img src={developmentGoal} alt="Objetivos de desarrollos" />
        </Col>
      </Row>
    </Container>
  );
}

export default DevelopmentGoal;