import React from "react";
import '../styles/components/_howToParticipate.scss';

//react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

//Images
import howToParticipateButton from "../assets/howToParticipate/BotonComoParticipar.png";
import iconInstructive from "../assets/howToParticipate/icon-instructive.png";
import iconCommunity from "../assets/howToParticipate/icon-community.png";
import iconacceptance from "../assets/howToParticipate/icon-acceptance.svg";
import iconProposal from '../assets/howToParticipate/iconProposal.svg';
import iconPrecautions from '../assets/howToParticipate/iconPrecautions.svg';
import iconPPD from '../assets/howToParticipate/iconPPD.svg';

const HowToParticipate = () => {
	return (
		<Container fluid={true} className="HowToParticipate_container-main">
			<Container>
				<Row>
					<Col xs={12} className="HowToParticipate_title-image">
						<img src={howToParticipateButton} alt="Como participar" />
					</Col>
				</Row>
				<Row className="HowToParticipate_container-participate mb-3">
					<Col xs={2} md={1} className="HowToParticipate_participate-number">
						1
					</Col>
					<Col
						xs={10}
						md={5}
						className="HowToParticipate_participate-preparative text-center"
					>
						<div className="HowToParticipate_participate-preparative-title">
							Preparativos
						</div>
						<div className="HowToParticipate_participate-preparative-images">
							<div>
								<img src={iconInstructive} alt="Instructivo" />
							</div>
							<div>
								<img src={iconCommunity} alt="Comunidad" />
							</div>
							<div>
								<img src={iconacceptance} alt="Aceptación" />
							</div>
						</div>
					</Col>
					<Col xs={12} md={6} className="HowToParticipate_participate-text">
						<ul>
							<li>
								{`
									Revisa la Estrategia de País del PPD Venezuela, el Instructivo de Inscripción y Propuesta de Proyecto y la Lista de veriﬁcación de recaudos.
								`}
							</li>
							<li>
								{`
									Reúnete con la comunidad para identiﬁcar el problema ambiental y su
									posible solución por medio de un proyecto del PPD. Revisa Cómo
									formular con éxito una Propuesta de Proyecto del PPD
								`}
							</li>
							<li>
								{`
									Obtén la aceptación y compromiso de la organización y de la comunidad para la realización de proyecto.
								`}
							</li>
						</ul>
					</Col>
				</Row>
				{/* ***************** */}
				<Row className="HowToParticipate_container-proposal mb-3">
					<Col xs={2} md={1} className="HowToParticipate_proposal-number">
						2
					</Col>
					<Col
						xs={10}
						md={5}
						className="HowToParticipate_participate-proposal text-center"
					>
						<div className="HowToParticipate_participate-proposal-title">
							Propuesta de Proyecto y recaudos
						</div>
						<div className="HowToParticipate_participate-preparative-images">
							<div>
								<img src={iconProposal} alt="Instructivo" />
							</div>
							<div>
								<img src={iconPrecautions} alt="Comunidad" />
							</div>
						</div>
					</Col>
					<Col xs={12} md={6} className="HowToParticipate_proposal-text">
						<ul>
							<li>
								{`
									Llena el Formato de Propuesta de Proyecto según instrucciones de la
									Parte 2 del Instructivo de Inscripción y Propuesta de Proyecto.
								`}
							</li>
							<li>
								{`
									Llena la Planilla de Inscripción y reúne los recaudos según la Lista de
									veriﬁcación de recaudos.
								`}
							</li>
						</ul>
					</Col>
				</Row>
				{/* ************** */}
				<Row className="HowToParticipate_container-shipping mb-3">
					<Col xs={2} md={1} className="HowToParticipate_shipping-number">
						1
					</Col>
					<Col
						xs={10}
						md={5}
						className="HowToParticipate_participate-shipping text-center"
					>
						<div className="HowToParticipate_participate-shipping-title">
							Envió al PPD
						</div>
						<div className="HowToParticipate_participate-preparative-images">
							<div>
								<img src={iconPrecautions} alt="Instructivo" />
							</div>
							<div>
								<img src={iconProposal} alt="Comunidad" />
							</div>
							<div>
								<img src={iconPPD} alt="Aceptación" />
							</div>
						</div>
					</Col>
					<Col xs={12} md={6} className="HowToParticipate_participate-text">
						<ul>
							<li>
								{`
									Envía tu Propuesta de Proyecto y recaudos de inscripción en físico o
									digital a nuestra oﬁcina o dirección de correo electrónico.
								`}
							</li>
							<li>
								{`
									Envía tu Propuesta de Proyecto y recaudos de inscripción en físico o
									digital a nuestra oﬁcina o dirección de correo electrónico.
									Recibe conﬁrmación de recepción.
								`}
							</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};
export default HowToParticipate;
