import React from "react";
import "../styles/components/_projectPorfolio.scss";

//react bootstrap
import { Container, Row, Col } from "react-bootstrap";

//images
import projectPortfolioTitle from "../assets/projectPortfolio/projectPortfolioTitle.svg";

const ProjectPortfolio = () => {
  return (
    <Container fluid={true} className="ProjectPortfolio_container-main">
      <Container>
        <Row>
          <Col xs={12} className="ProjectPortfolio_container-title">
            <img src={projectPortfolioTitle} alt="Portafolio de Proyectos" />
          </Col>
          <Col xs={12} className="ProjectPortfolio_container-paragraph">
            <p>
              {`
                Durante los primeros nueve años (2010-2019) el PPD Venezuela financió proyectos en todo 
                el territorio nacional, pero a partir del 2020, con el inicio de la séptima fase operacional 
                (OP7), se adoptó definitivamente el enfoque en territorios y paisajes priorizados. 
                Aproximadamente, un 97% de los proyectos financiados pertenecen a la categoría de regulares, 
                mientras que un 3% son proyectos estratégicos.
              `}
            </p>
            <p>
              {`
                Como puede verse en las actualizaciones de los resultados cuantitativos del programa país en la 
                sección inicio, el área focal con mayor número de proyectos financiados ha sido biodiversidad 
                (>50%), seguida de cambio climático (> 30%) y degradación de tierras (<15%). Los proyectos de 
                generación de capacidades no superan el 5%.
              `}
            </p>
            <p>
              {`
                El énfasis de los proyectos ha estado en la conservación de los paisajes terrestres y marinos por 
                las comunidades mediante el turismo rural de base comunitaria, la gestión sostenible de la tierra 
                focalizada sobre sistemas de producción familiar y el uso sostenible de la biodiversidad de 
                importancia para la agricultura, en cultivos amigables con la biodiversidad (café y cacao), 
                como elementos dentro del paisaje y dinamizadores de medios de vida sostenibles. Esto evidencia 
                una estrecha relación con las características físico-naturales del país, con una diversidad biológica 
                entre las más altas de la región.
              `}
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ProjectPortfolio;
