import React, { useContext, useEffect } from "react";
import AppContext from "../context/AppContext.js";
import { Helmet } from "react-helmet";
import "../styles/pages/_dashboard.scss";
import { Link, Outlet } from "react-router-dom";

import Footer from "../components/Footer.jsx";
import Login from "../components/Login.jsx";
import Unore from "../components/dashboard/Unore.jsx";
import SaveNews from "../components/dashboard/SaveNews.jsx";
import SubMenu from "../components/dashboard/SubMenu.jsx";

const Dashboard = () => {
  const { state, verifyToken, logout } = useContext(AppContext);

  document.body.className = "notfound-background";

  useEffect(() => {
    verifyToken();
  }, []);

  return (
    <>
      <Helmet>
        <title>PPD - Dashboard</title>
      </Helmet>

      {state.securityToken === null ? (
        <Login />
      ) : (
        <div className="dashboard__container">
          <SubMenu/>
          

          <Outlet />
        </div>
      )}
    </>
  );
};

export default Dashboard;
