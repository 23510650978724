import React from "react";
import { Helmet } from "react-helmet";
import Banner from "../components/Banner.jsx";
import { Container, Row, Col } from "react-bootstrap";
import ListNews from '../components/news/ListNews.jsx'
import Footer from "../components/Footer.jsx";
// import AllNews from "../components/AllNews.jsx";
import Updates from "../components/Updates.jsx";
import ContactForm from "../components/ContactForm.jsx";

const Home = () => {
  document.body.className = "";
  return (
    <>
      <Helmet>
        <title>PPD - Inicio</title>
      </Helmet>

      <Container fluid={true} className="p-0">
        <Row>
          <Col className="p-0">
            <Banner />
          </Col>
        </Row>
      </Container>
      
      <ListNews/>


      {/* <AllNews /> */}
       <Updates />
        <ContactForm /> 
      
      <Footer bgColor="Footer_bg-footer-orange" />
    </>
  );
};

export default Home;
