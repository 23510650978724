import React from "react";
import { Helmet } from "react-helmet";

//components
import CountryStrategy from "../components/CountryStrategy.jsx";
import Instructives from "../components/Instructives.jsx";
import Innovation from "../components/Innovation.jsx";
import Footer from "../components/Footer";

const Downloads = () => {
  document.body.className = "";

  <Helmet>
    <title>PPD - Descargas</title>
  </Helmet>;

  return (
    <>
      <Helmet>
        <title>PPD - Descargas</title>
      </Helmet>
      <CountryStrategy />
      <Instructives />
      <Innovation />
      <Footer bgColor="Footer_bg-footer-green" />
    </>
  );
};

export default Downloads;
