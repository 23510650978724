import React from "react";
import "../styles/components/_countriesWork.scss";
import ArrowCountriesWork from "./svg/ArrowCountriesWork";

//images
import titleCountriesWork from "../assets/projectMap/countriesWhereWeWork.svg";
import FiguraOne from "../assets/countriesWork/FiguraOne.jpg";
import FiguraTwo from "../assets/countriesWork/FiguraTwo.jpg";

import { Container, Row, Col } from "react-bootstrap";

const CountriesWork = () => {
  return (
    <>
      <Container fluid={true} className="CountriesWork_container-main">
        <Container>
          <Row>
            <Col xs={12} className="CountriesWork_title-image">
              <img src={titleCountriesWork} alt="Mapas de proyectos" />
            </Col>
            <Col xs={12} className="CountriesWork_container-paragraph">
              <p>
                {`Para el Programa de Pequeñas Donaciones el enfoque del paisaje tiene una importancia 
                  relevante. Eso signiﬁca que se debe actualizar la Estrategia para el Programa del
                  País en cada fase operacional, seleccionando territorios y paisajes prioritarios e implementar
                  una estrategia de concesión de donaciones apropiada a este enfoque. Este proceso se realizó 
                  recientemente para dar inicio a la séptima fase operacional (OP7) del
                  PPD, mediante una consulta pública amplia con diferentes sectores de la sociedad civil
                  venezolana.`}
              </p>
              <p>
                {`Por decisión mayoritaria durante la consulta pública, la selección se hizo a dos escalas:
                  grandes territorios prioritarios (regiones) y paisajes relevantes dentro de cada territorio, 
                  adoptando una estrategia de implementación 70-30% para la concesión de donaciones. 
                  Una vez priorizados los territorios y paisajes relevantes, se deﬁnieron sus prioridades 
                  ambientales y actividades principales, y se realizó la evaluación de línea base.
                  Finalmente, se elaboraron los mapas de territorios y paisajes.`}
              </p>
              <p>
                {`La Figura 1 y 2 muestran el mapa con los grandes territorios priorizados por los actores
                  consultados y los paisajes relevantes dentro de cada territorio. En orden de prioridad,
                  los territorios fueron: Región marino-costera norte, región andina, región Guayana y
                  región llanos.`}
              </p>
            </Col>
            <Col xs={12} className="d-flex justify-content-center pt-2">
              <div className="CountriesWork_container-arrow-countries">
                <ArrowCountriesWork />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="CountriesWork_container--figura-one">
        <Row>
          <Col xs={12} className="figura-one__container">
            <p>{`Figura 1. Mapa de territorios priorizados`}</p>
            <img
              src={FiguraOne}
              alt="Figura del mapa de territorios priorizados"
            />
          </Col>
        </Row>
      </Container>

      <Container fluid={true} className="CountriesWork_container-main">
        <Container className="mb-4">
          <Row>
            <Col xs={12} className="figura-two__container">
              <p>{`Figura 2. Mapa de paisajes relevantes`}</p>
              <img
                src={FiguraTwo}
                alt="Figura del mapa de paisajes relevantes"
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default CountriesWork;
