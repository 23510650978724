import React from "react";
import '../styles/components/_projectMap.scss';

//images
import titleProjectMap from "../assets/projectMap/titleProjectMap.svg";
import projectsMap from "../assets/projectMap/projectsMap.jpg";
import iconSearchMap from "../assets/projectMap/iconSearchMap.svg";

//react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

const ProjectMap = () => {
	return (
		<>
			<Container className="ProjectMap_container-main">
				<Row>
					<Col xs={12} className="ProjectMap_title-image text-right">
						<img src={titleProjectMap} alt="Mapas de proyectos" />
					</Col>
					<Col xs={12} className="ProjectMap_subtitle-map">
						El siguiente mapa muestra los grandes territorios priorizados por los actores consultados.
					</Col>
					<Col xs={12} className="project-map__image">
						<img src={projectsMap} alt="Mapas de proyectos" />
					</Col>
					<Col xs={12} className="project-map__icon-search text-center">
						<a
							href={`https://sgp.undp.org/component/countrypages/?view=countrypage&country=117&Itemid=271`}
							target="blank"
						>
							<img src={iconSearchMap} alt="Boton ir a mapas de proyectos" />
						</a>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default ProjectMap;
