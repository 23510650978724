import React, { useContext } from "react";
import AppContext from "../../context/AppContext.js";
import { NavLink } from "react-router-dom";
import "../../styles/components/dashboard/_submenu.scss";
import { Container, Row, Col } from "react-bootstrap";

const SubMenu = () => {
  const { logout, state } = useContext(AppContext);

  return (
    <>
      <Container className="my-4">
        <Row>
          <Col xs={12} className="SubMenu__container">
            <ul className="flex-column flex-lg-row">
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "SubMenu__container--active" : undefined
                  }
                  to={`unore`}
                >
                  Unore
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "SubMenu__container--active" : undefined
                  }
                  to={`news`}
                >
                  Noticias
                </NavLink>
              </li>
              {state.user.rol[0] === "administrator" && (
                <li>
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "SubMenu__container--active" : undefined
                    }
                    to={`users`}
                  >
                    Usuarios
                  </NavLink>
                </li>
              )}
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "SubMenu__container--active" : undefined
                  }
                  to={`actualizacion`}
                >
                  Actualizaciones
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "SubMenu__container--active" : undefined
                  }
                  to={`banner`}
                >
                  Banner
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "SubMenu__container--active" : undefined
                  }
                  to={`Innovation`}
                >
                  Innovación
                </NavLink>
              </li>
              <li>
                <button className="" onClick={() => logout()}>
                  Cerrar sesión
                </button>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SubMenu;
