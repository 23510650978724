import React from 'react';

const Success = (props) => {
  const {text} = props;
  return (
    <div className='alert alert-success Success__container'>
      <p className='m-2'>{text}</p>
    </div>
  );
};

export default Success;