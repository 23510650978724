import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AppContext from "../../context/AppContext.js";
import "../../styles/components/news/_detailNews.scss";

//react-bootstrap
import { Container, Row, Col } from "react-bootstrap";


const DetailNews = () => {
  let { newsId } = useParams();
  const { state } = useContext(AppContext);
  let navigate = useNavigate();
  const [news, setNews] = useState({});
  const {
    title,
    subtitle,
    image,
    complete,
    highlighted,
    image_secondary,
  } = news;

  document.body.className = "clasePrueba";

  useEffect(() => {
    const getNews = (id) => {
      return state.news.find((item) => item.id === Number(id));
    };
    const news = getNews(newsId);
    if (news !== undefined) {
      setNews(news);
    }

  }, [state.news, newsId]);

  return (
    <>
      <Container>
        <Row className="detail-news__row justify-content-center">
          <Col xs={12} md={8}>
            {title && <h1 className="detail-news__title">{title}</h1>}
            {subtitle && <h3 className="detail-news__subtitle">{subtitle}</h3>}

            <div className="detail-news__image-container">
              <img src={image} alt={``} />
            </div>

            <div
              className="detail-news__news-main"
              dangerouslySetInnerHTML={{ __html: complete }}
            />

            {highlighted && (
              <div className="detail-news__highlighted">
                <p>{highlighted}</p>
              </div>
            )}

            {image && (
              <div className="detail-news__image-container">
                <img src={image_secondary} alt={``} />
              </div>
            )}
          </Col>

          <Col xs={12} md={8} className="detail-news__button-back">
            <button onClick={() => navigate(-1)}>Volver</button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DetailNews;

