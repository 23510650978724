import React from "react";
import '../styles/components/_projectCicles.scss';

//images
import titleProjectCicles from "../assets/projectCicles/titleProjectCicles.svg";
import iconInscription from "../assets/projectCicles/iconInscriptions.svg";

//react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

const ProjectCicles = () => {
	return (
		<Container fluid={true} className="ProjectClicles_container-main">
			<Container>
				<Row>
					<Col className="ProjectCicles_title-container">
						<img src={titleProjectCicles} alt="Project Cicles" />
					</Col>
				</Row>
				<Row className="mt-5 mb-2">
					<Col xs={12} md={8} className="ProjectCicles_inscriptions">
						<div className="ProjectCicles_inscriptions-container">
							<div>
								<p>
									{`INSCRIPCIÓN Y
                    PROPUESTA DE
                    PROYECTO`}
								</p>
							</div>
							<div className="">
								<img src={iconInscription} alt="Inscriptions" />
							</div>
							<div>
								<ul>
									<li>
										{`
                      Entrega de recaudos y documentos de la
                      organización (ver lista de veriﬁcación.
                    `}
									</li>
									<li>
										{`
                      Entrega de Propuesta de Proyecto idea
                      general y esquemática del proyecto.
                    `}
									</li>
								</ul>
							</div>
						</div>
					</Col>
				</Row>

				<Row className="justify-content-end">
					<Col xs={12} md={8} className="ProjectCicles_approval my-2">
						<div className="ProjectCicles_inscriptions-container">
							<div>
								<ul>
									<li>
										{`
										El Comité Directivo Nacional (CDN)
										del PPD evalúa y aprueba las
										propuestas de proyectos recibidas.
									`}
									</li>
								</ul>
							</div>
							<div>{/* Aqui va la imagen */}</div>
							<div>
								<p>
									{`
										APROBACIÓN
										DE PROPUESTA
									`}
								</p>
							</div>
						</div>
					</Col>
				</Row>

				<Row className="my-2">
					<Col xs={12} md={8} className="ProjectCicles_document">
						<div className="ProjectCicles_document-container">
							<div>
								<p>
									{`
										DOCUMENTO
										DE PROYECTO
									`}
								</p>
							</div>
							<div className="">
								{/* <img src={iconInscription} alt="Inscriptions" /> */}
							</div>
							<div>
								<ul>
									<li>
										{`
                      Las organizaciones con propuestas
											aprobadas elaboran el Documento de
											Proyecto, el cual es el proyecto
											desarrollado en detalle.
                    `}
									</li>
									<li>
										{`
                     El CDN aprueba Documento de Proyecto.
                    `}
									</li>
								</ul>
							</div>
						</div>
					</Col>
				</Row>

				<Row className="justify-content-end">
					<Col xs={12} md={8} className="ProjectCicles_approval my-2">
						<div className="ProjectCicles_inscriptions-container">
							<div>
								<ul>
									<li>
										{`
										Firma del contrato ( Memorándum de
										Acuerdo - MOA) entre la organización y
										el PPD.
									`}
									</li>
									<li>
										{`
										Entrega del 1er Desembolso (50%).
									`}
									</li>
								</ul>
							</div>
							<div>{/* Aqui va la imagen */}</div>
							<div>
								<p className="px-md-4">
									{`
										FIRMA DE MOA 
									`}
								</p>
							</div>
						</div>
					</Col>
				</Row>

				<Row className="my-2">
					<Col xs={12} md={8} className="ProjectCicles_document">
						<div className="ProjectCicles_document-container">
							<div>
								<p>
									{`
										EJECUCIÓN
										DEL PROYECTO
									`}
								</p>
							</div>
							<div className="">
								{/* <img src={iconInscription} alt="Inscriptions" /> */}
							</div>
							<div>
								<ul>
									<li>
										{`
                     Rendición 1er Informe (técnico-ﬁnanciero).
                    `}
									</li>
									<li>
										{`
                     	Entrega del 2do Desembolso (40%).
                    `}
									</li>
									<li>
										{`
											Visitas de monitoreo y evaluación.
										`}
									</li>
								</ul>
							</div>
						</div>
					</Col>
				</Row>

				<Row className="justify-content-end">
					<Col xs={12} md={8} className="ProjectCicles_approval my-2">
						<div className="ProjectCicles_inscriptions-container">
							<div>
								<ul>
									<li>
										{`
										Rendición de Informe Final y
										lecciones aprendidas.
									`}
									</li>
									<li>
										{`
										Entrega del 3er Desembolso (10%).
									`}
									</li>
									<li>
										{`
										Rendición de Anexo de Informe
										Final (rendición de 10% restante).
									`}
									</li>
								</ul>
							</div>
							<div>{/* Aqui va la imagen */}</div>
							<div>
								<p className="px-md-4">
									{`
										CIERRE Y
										EVALUACIÓN
										DE PROYECTO
									`}
								</p>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default ProjectCicles;
