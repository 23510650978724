import React from "react";
import {Helmet} from 'react-helmet'

//component
import HowToParticipate from '../components/HowToParticipate.jsx'
import FormulateProposal from "../components/FormulateProposal.jsx";
import ProjectCicles from "../components/ProjectCicles.jsx";
import DevelopmentGoal from "../components/DevelopmentGoal.jsx";
import Footer from "../components/Footer.jsx";

const Participate = () => {
  document.body.className = "";
  return (
    <>
    <Helmet>
      <title>PPD - Participa</title>
    </Helmet>
      <HowToParticipate />
      <FormulateProposal />
      <ProjectCicles />
      <DevelopmentGoal />
      <Footer bgColor="Footer_bg-footer-blue" />
    </>
  );
};

export default Participate;
