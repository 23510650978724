import React from "react";

function ArrowCountriesWork(props) {
  return (
    <svg viewBox="0 0 24.42 23.55">
      <g id="Capa_2" data-name="Capa 2" fill="#5a4109">
        <polygon
          className="cls-1"
          points="16.46 10.25 16.46 0 7.96 0 7.96 10.25 0 10.25 12.21 23.55 24.42 10.25 16.46 10.25"
        />
      </g>
    </svg>
  );
}

export default ArrowCountriesWork;
