import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const NotFound = () => {
  document.body.className = "notfound-background";
  return (
    <>
      <Helmet>
        <title>404 - Not Found</title>
      </Helmet>

      <div className="notfound-container">
        <h1>
          404 No encontrada, <Link to={`/`}>ir al inicio</Link>
        </h1>
      </div>
    </>
  );
};

export default NotFound;
