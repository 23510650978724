import React, { useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AppContext from "../../context/AppContext";
import "../../styles/components/dashboard/_savenews.scss";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const initialState = {
  title: "",
  subtitle: "",
  summary: "",
  image: "",
  image_secondary: "",
  image_preview: "",
  complete: "",
  highlight: "",
};

const initialError = {
  title: false,
  image: false,
  complete: false,
  summary: false,
  image_preview: false,
};

const SaveNews = () => {
  const { saveNews, state, deleteNews } = useContext(AppContext);
  const [news, setNews] = useState(initialState);
  const [error, setError] = useState(initialError);

  const { title, image, complete, summary, image_preview } = news;

  const navigate = useNavigate();

  const handleChanges = ({ target }) => {
    setNews((state) => ({
      ...state,
      [target.name]: target.value,
    }));
  };

  const handleSubmit = async (e) => {
    if (
      title.trim() === "" ||
      image === "" ||
      image_preview === "" ||
      complete.trim() === ""
    ) {
      setError({
        ...error,
        title: true,
        image: true,
        image_preview: true,
        complete: true,
      });
      return;
    }

    const formData = new FormData();
    formData.append("title", news.title);
    formData.append("subtitle", news.subtitle);
    formData.append("summary", news.summary);
    formData.append("highlight", news.highlight);
    formData.append("image", news.image);
    formData.append("image_secondary", news.image_secondary);
    formData.append("image_preview", news.image_preview);
    formData.append("complete", news.complete);

    const response = await saveNews(formData);

    if (response === 401) {
      navigate("/administrador");
    } else if (response === 201) {
      setNews(initialState);
      setError(initialError);
    }

    setError({
      title: false,
    });
  };

  const handleDeleteNews =(id)=> {
   
    Swal.fire({
      title: 'Estas seguro?',
      text: "No podrás revertit esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, borralo!',
    }).then((result) => {
      if (result.isConfirmed) {
       deleteNews(id)
        .then((response) => {
          if (response === 401) {
            navigate("/administrador");
          } else if (response === 204) {
            Swal.fire(
              'Borrado!',
              'Tu noticia ha sido borrada.',
              'success'
            )
          }else{
            Swal.fire(
              'Error!',
              'Ha ocurrido un error.',
              'error'
            )
          }
        })
      }
    })
  }

  // const handleEditNews = (id) => {
  //   console.log("editar la noticia")
  // }
  return (
    <Container>
      <Row className="justify-content-center mb-4">
        <Col
          xs={12}
          md={8}
          lg={7}
          xl={6}
          className="SaveNews__container-col my-3"
        >
          <form
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <div className="form-group">
              <label id="titleMain">Título principal:</label>
              <textarea
                type="text"
                id="titleMain"
                placeholder="Título principal"
                className="form-control"
                name="title"
                value={news.title}
                onChange={handleChanges}
              ></textarea>
              {error.title && (
                <p className="text-danger">El campo es obligatorio</p>
              )}
            </div>

            <div className="form-group my-3">
              <label htmlFor="subtitle">Subtítulo:</label>
              <textarea
                type="text"
                placeholder="Subtítulo"
                className="form-control"
                id="subtitle"
                name="subtitle"
                value={news.subtitle}
                onChange={handleChanges}
              ></textarea>
            </div>

            <div className="form-group my-3">
              <label htmlFor="summary">Resumen :</label>
              <textarea
                type="text"
                placeholder="Resumen"
                className="form-control"
                name="summary"
                id="summary"
                value={news.summary}
                onChange={handleChanges}
              ></textarea>
            </div>

            <div className="form-group my-3">
              <label htmlFor="exampleInputEmail1">destacado:</label>
              <textarea
                type="text"
                placeholder="Ingrese mensaje destacado"
                className="form-control"
                name="highlight"
                value={news.highlight}
                onChange={handleChanges}
              ></textarea>
            </div>

            <div className="form-group my-3">
              <label htmlFor="exampleInputEmail1">Imagen Principal:</label>
              <input
                type="file"
                className="form-control"
                name="image"
                onChange={(event) => {
                  setNews({ ...news, image: event.target.files[0] });
                }}
              />
              {error.image && (
                <p className="text-danger">El campo es obligatorio</p>
              )}
            </div>

            <div className="form-group my-3">
              <label htmlFor="exampleInputEmail1">Imagen Secundaria:</label>
              <input
                type="file"
                className="form-control"
                name="image_secondary"
                onChange={(event) => {
                  setNews({ ...news, image_secondary: event.target.files[0] });
                }}
              />
            </div>

            <div className="form-group my-3">
              <label htmlFor="exampleInputEmail1">Imagen Resumen:</label>
              <input
                type="file"
                className="form-control"
                name="image_preview"
                // value={news.image_preview?.name}
                onChange={(event) => {
                  setNews({ ...news, image_preview: event.target.files[0] });
                }}
              />
              {error.image_preview && (
                <p className="text-danger">El campo es obligatorio</p>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Noticia:</label>
              <textarea
                type="text"
                placeholder="Ingrese Noticia"
                className="form-control"
                name="complete"
                value={news.complete}
                onChange={handleChanges}
              ></textarea>
              {error.complete && (
                <p className="text-danger">El campo es obligatorio</p>
              )}
            </div>

            <div className="form-group SaveNews__container-col--button">
              <button type="button" onClick={() => handleSubmit()}>
                Publicar Noticia
              </button>
            </div>
          </form>
        </Col>
      </Row>
      <Row className="mb-5">
        {state.news.map((item) => {
          return (
            <Col
              xs={12}
              key={item.id}
              className="d-flex flex-column flex-md-row py-2 justify-content-between SaveNews__container-allnews my-2"
            >
              <div className="d-flex">
                <p>{item.id}</p>
                <p>{item.title}</p>
              </div>
              <div>
                <button
                  onClick={() => {
                    navigate(`/administrador/editar-noticia/${item.id}`);
                  }}
                >Actualizar</button>

                <button
                  onClick={() => {
                    handleDeleteNews(item.id);
                  }}
                >
                  Borrar
                </button>
              </div>
            </Col>
            
          );
        })}
      </Row>
    </Container>
  );
};

export default SaveNews;
