import React from 'react';
import '../styles/components/_countryStrategy.scss';

//react-bootstrap
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';

//images
import programStrategy from '../assets/countryStrategy/programStrategy.svg';
import buttonStrategySee from '../assets/countryStrategy/buttonStrategySee.svg';


const CountryStrategy = () => {
    return (
        <Container className="CountryStrategy_container-main" fluid={true}>
            <Container >
                <Row className="CountryStrategy_container-strategy-img">
                    <Col xs={12}>
                        <img src={programStrategy} alt="Estrategia Programa Pais" />
                    </Col>
                </Row>
                <Row className="CountryStrategy_container-info">
                    <Col md={5}>
                        <img src="https://ppdvenezuela.org/documentos/images/countryStrategy.jpg" alt="Estrategia para el programa pais" />
                    </Col>
                    <Col md={7}>
                        <p>
                            Estrategia para el programa del país para la séptima fase operacional
                            (OP7) del Programa de Pequeña Donaciones (PPD) <br />
                            Venezuela 2020 - 2024.
                        </p>
                        <div className="CountryStrategy_container-see-more">
                            <a href={`https://ppdvenezuela.org/documentos/pdfs/estrategia_programa_pais.pdf`} target="_blank" rel="noreferrer">
                                <img src={buttonStrategySee} alt="Ver más" />
                            </a>

                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default CountryStrategy;