import React from "react";
import '../styles/components/_structure.scss';

import { Container, Row, Col } from "react-bootstrap";

import structure from "../assets/structure/titleStructure.svg";
import cdn from "../assets/structure/cdn.svg";
import equipoPais from "../assets/structure/equipoPais.svg";
import beneficiarios from "../assets/structure/beneficiarios.svg";

const Structure = () => {
  return (
    <Container fluid={true} className="Structure_container-main">
      <Container>
        <Row>
          <Col xs={12} className="Structure_title-image">
            <img src={structure} alt="Estructura" />
          </Col>
        </Row>
        <Row className="Structure_structure-main">
          <Col xs={12} md={5} className="text-center text-md-right">
            <img src={cdn} alt="Comité Directivo Nacional" />
          </Col>
          <Col xs={12} md={7} className="px-5">
            <h3>Comité Directivo Nacional</h3>
            <p>
              {`El CDN es el grupo que se encarga de la revisión y
								aprobación de las propuestas de proyectos, así como
								de la deﬁnición de las estrategias, prioridades y enfoque 
								del Programa Pequeñas Donaciones en Venezula (PPD). 
								Está compuesto por representantes del Gobierno Nacional (MINEC), del PNUD Venezuela, de
								Universidades, de ONG ambientalistas y de Organizaciones Comunitarias ambientalista.`}
            </p>
          </Col>
          <Col xs={12} md={5} className="text-center text-md-right">
            <img src={equipoPais} alt="Equipo País" />
          </Col>
          <Col xs={12} md={7} className="px-5">
            <h3>Equipo País</h3>
            <p>
              {`Es el equipo encargado de la administración del Programa, 
							así como del seguimiento y evaluación administrativa 
							y programática del los proyectos. Lo integran el Coordinador Nacional y el Asistente del Programa.`}
            </p>
          </Col>
          <Col xs={12} md={5} className="text-center text-md-right">
            <img src={beneficiarios} alt="Beneﬁciarios" />
          </Col>
          <Col xs={12} md={7} className="px-5">
            <h3>Beneﬁciarios</h3>
            <p>
              {`Son las organizaciones y comunidades que reciben
								apoyo ﬁnanciero y técnico a través del Programa. Son
								los encargados de la planiﬁcación y ejecución de los
								proyectos del PPD en Venezuela.`}
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Structure;
