import React from "react";
import '../styles/components/_instructives.scss';

//react-bootstrap
import { Container, Row, Col } from "react-bootstrap";

//images
import buttonInstructive from "../assets/instructives/buttonInstructives.svg";
import proposalIcon from "../assets/instructives/proposalIcon.svg";
import surrenderIcon from "../assets/instructives/surrenderIcon.svg";
import documentIcon from "../assets/instructives/documentIcon.svg";
import downloadBlue from "../assets/instructives/downloadBlue.svg";
import downloadGreen from "../assets/instructives/downloadGreen.svg";
import dowloandOrange from "../assets/instructives/downloadOrange.svg";

const Instructives = () => {
  return (
    <Container>
      <Row>
        <Col
          xs={12}
          className="d-flex justify-content-end Instructives_container-format-img"
        >
          <img src={buttonInstructive} alt="Instructivos y Formatos" />
        </Col>
      </Row>
      <Row className={`Instructives_container-format-document`}>
        <Col xs={12} md={4} className="text-center">
          <img src={documentIcon} alt="Documentos del proyecto" />
          <p>{`Documentos del proyecto`}</p>
          <a href={`https://ppdvenezuela.org/documentos/instructivos/documentos_del_proyecto.zip`} download="documentos_del_proyecto.zip">
            <img
              src={dowloandOrange}
              className="Instructives_img-download"
              alt="Descargar"
            />
          </a>
        </Col>

        <Col xs={12} md={4} className="text-center">
          <img src={proposalIcon} alt="Propuesta del proyecto" />
          <p>{`Propuesta del proyecto`}</p>
          <a href={`https://ppdvenezuela.org/documentos/instructivos/propuesta_del_proyecto.zip`} download="propuesta_del_proyecto.zip">
            <img
              src={downloadGreen}
              className="Instructives_img-download"
              alt="Descargar"
            />
          </a>
        </Col>

        <Col xs={12} md={4} className="text-center">
          <img src={surrenderIcon} alt="Rendición Técnico - Financiera" />
          <p>{`Rendición Técnico - Financiera`}</p>
          <a href={`https://ppdvenezuela.org/documentos/instructivos/rendicion_tecnico.zip`} download="rendicion_tecnico.zip">
            <img
              src={downloadBlue}
              className="Instructives_img-download"
              alt="Descargar"
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default Instructives;
