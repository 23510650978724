import { useEffect, useState } from "react";

const initialState = {
  unore: "",
  securityToken: window.localStorage.getItem("token") || null,
  news: [].reverse(),
  user: JSON.parse(window.localStorage.getItem("user")) || {},
  users: [],
  updates: [],
  banners: [],
  innovation: [],
};

//const urlBase = "http://localhost:3001/api";
const urlBase = "https://ppdvenezuela.herokuapp.com/api";

const useInitialState = () => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const getUnore = async () => {
      const response = await fetch(`${urlBase}/unore`);
      const data = await response.json();
      setState((state) => ({
        ...state,
        unore: data[data.length - 1],
      }));
    };

    getUnore();
  }, []);

  const saveUnore = async (priceUnore) => {
    try {
      const response = await fetch(`${urlBase}/unore`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.securityToken}`,
        },
        body: JSON.stringify(priceUnore),
      });
      if (response.ok) {
        return response.status;
      }
      return response.status;
    } catch (error) {
      console.log(error);
    }
  };

  const verifyToken = async () => {
    try {
      const response = await fetch(`${urlBase}/verify`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.securityToken}`,
        },
      });
      if (!response.ok) {
        logout();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const login = async (user) => {
    const response = await fetch(`${urlBase}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });
    const data = await response.json();
    if (data.access_token) {
      setState((state) => ({
        ...state,
        securityToken: data.access_token,
        user: data.user,
      }));
      window.localStorage.setItem("token", data.access_token);
      window.localStorage.setItem("user", JSON.stringify(data.user));
      return true;
    }
    return false;
  };

  const logout = () => {
    setState((state) => ({
      ...state,
      securityToken: null,
      user: {},
    }));
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("user");
  };

  const saveNews = async (news) => {
    try {
      const response = await fetch(`${urlBase}/news`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${state.securityToken}`,
        },
        body: news,
      });
      if (response.ok) {
        return response.status;
      }
      return response.status;
    } catch (error) {
      console.log(error);
    }
  };

  const getNews = async () => {
    try {
      const response = await fetch(`${urlBase}/news`);
      const data = await response.json();

      setState((state) => ({
        ...state,
        news: data.reverse(),
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const deleteNews = async (id) => {
    try {
      const response = await fetch(`${urlBase}/news/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${state.securityToken}`,
        },
      });
      if (response.ok) {
        getNews();
        return response.status;
      }
      return response.status;
    } catch (error) {
      console.log(error);
    }
  };

  const sendEmailContact = async (data) => {
    try {
      const response = await fetch(`${urlBase}/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        return response.status;
      }
    } catch (error) {
      console.log(error);
      return 500;
    }
  };

  const getAllUsers = async () => {
    try {
      const response = await fetch(`${urlBase}/user`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${state.securityToken}`,
        },
      });

      const data = await response.json();
      if (response.ok) {
        setState((state) => ({
          ...state,
          users: data,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateNews = async (news) => {
    try {
      const response = await fetch(`${urlBase}/news/${news.get("id")}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${state.securityToken}`,
        },
        body: news,
      });

      if (response.ok) {
        getNews();
        return response.status;
      }
      return response.status;
    } catch (error) {
      console.log(error);
      return 500;
    }
  };

  const getUpdates = async () => {
    try {
      const response = await fetch(`${urlBase}/actualizacion`);
      const data = await response.json();

      setState((state) => ({
        ...state,
        updates: data,
      }));
    } catch (error) {
      console.log(error);
      return 500;
    }
  };

  const createActualizacion = async (actualizacion) => {
    try {
      const response = await fetch(`${urlBase}/actualizacion`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.securityToken}`,
        },
        body: JSON.stringify(actualizacion),
      });
      if (response.ok) {
        getUpdates();
        return response.status;
      }
      return response.status;
    } catch (error) {
      console.log(error);
      return 500;
    }
  };

  const updateActualizacion = async (data, id) => {
    try {
      const response = await fetch(`${urlBase}/actualizacion/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.securityToken}`,
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        getUpdates();
        return response.status;
      }
      return response.status;
    } catch (error) {
      console.log(error);
      return 500;
    }
  };

  const deleteActualizacion = async (id) => {
    try {
      const response = await fetch(`${urlBase}/actualizacion/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${state.securityToken}`,
        },
      });
      if (response.ok) {
        getUpdates();
        return response.status;
      }
      return response.status;
    } catch (error) {
      console.log(error);
      return 500;
    }
  };

  const getBanners = async () => {
    try {
      const response = await fetch(`${urlBase}/banner`);
      const data = await response.json();

      setState((state) => ({
        ...state,
        banners: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const createdBanner = async (banner) => {
    try {
      const response = await fetch(`${urlBase}/banner`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${state.securityToken}`,
        },
        body: banner,
      });
      if (response.ok) {
        getBanners();
        return response.status;
      }
      return response.status;
    } catch (error) {
      console.log(error);
      return 500;
    }
  };

  const deleteBanner = async (id) => {
    try {
      const response = await fetch(`${urlBase}/banner/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${state.securityToken}`,
        },
      });
      if (response.ok) {
        getBanners();
        return response.status;
      }
      return response.status;
    } catch (error) {
      console.log(error);
    }
  };

  const getInnovation = async () => {
    try {
      const response = await fetch(`${urlBase}/innovation`);
      const data = await response.json();

      setState((state) => ({
        ...state,
        innovation: data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const createdInnovation = async (innovation) => {
    try {
      const response = await fetch(`${urlBase}/innovation`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${state.securityToken}`,
        },
        body: innovation,
      });
      if (response.ok) {
        getInnovation();
        return response.status;
      }
      return response.status;
    } catch (error) {
      console.log(error);
      return 500;
    }
  };

  const deleteInnovation = async (id) => {
    try {
      const response = await fetch(`${urlBase}/innovation/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${state.securityToken}`,
        },
      });
      if (response.ok) {
        getInnovation();
        return response.status;
      }
      return response.status;
    } catch (error) {
      console.log(error);
      return 500;
    }
  };

  useEffect(() => {
    getNews();
    getUpdates();
    getBanners();
    getInnovation();
  }, []);

  return {
    state,
    login,
    saveNews,
    logout,
    verifyToken,
    saveUnore,
    deleteNews,
    sendEmailContact,
    getAllUsers,
    updateNews,
    getUpdates,
    createActualizacion,
    updateActualizacion,
    deleteActualizacion,
    createdBanner,
    deleteBanner,
    createdInnovation,
    deleteInnovation,
  };
};

export default useInitialState;
